import { lazy } from 'react';

const privateRoutes = [
  {
    path: '',
    element: lazy(() => import('pages/admin/Dashboard')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/admin/dashboard',
    element: lazy(() => import('pages/admin/Dashboard')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/admin/visitor',
    element: lazy(() => import('pages/admin/booking/BookingManagement')),
    useAuth: true,
    permission: 'booking_management'
  },
  {
    path: '/admin/booking-managemnent',
    element: lazy(() => import('pages/admin/booking')),
    useAuth: true,
    permission: 'booking_management'
  },
  {
    path: '/admin/payment-history',
    element: lazy(() => import('pages/admin/payment-history')),
    useAuth: true,
    permission: 'payment_history_management'
  },
  {
    path: '/admin/property-type',
    element: lazy(() => import('pages/admin/PropertyType')),
    useAuth: true,
    permission: 'view_propertytype'
  },
  {
    path: '/admin/partner-type',
    element: lazy(() => import('pages/admin/PartnerType')),
    useAuth: true,
    permission: 'view_partnertype'
  },
  {
    path: '/admin/bills',
    element: lazy(() => import('pages/admin/bills')),
    useAuth: true,
    permission: 'view_bill'
  },
  {
    path: '/admin/bills/new',
    element: lazy(() => import('pages/admin/bills/CreateBill')),
    useAuth: true,
    permission: 'add_bill'
  },
  {
    path: '/admin/bills/:id',
    element: lazy(() => import('pages/admin/bills/EditBill')),
    useAuth: true,
    permission: 'change_bill'
  },
  {
    path: '/admin/outstanding-payment',
    element: lazy(() => import('pages/admin/OutstandingPayments')),
    useAuth: true,
    permission: 'view_outstandingbill'
  },
  // {
  //   path: '/credit',
  //   element: lazy(() => import('pages/Credit')),
  //   useAuth: true
  // },
  {
    path: '/admin/resources',
    element: lazy(() => import('pages/admin/Resources')),
    useAuth: true,
    permission: 'add_information'
  },
  {
    path: '/admin/requests',
    element: lazy(() => import('pages/admin/estate-request')),
    useAuth: true,
    permission: 'view_request'
  },
  {
    path: '/admin/requests/me',
    element: lazy(() => import('pages/admin/estate-request')),
    useAuth: true,
    permission: 'view_request'
  },
  {
    path: '/admin/requests/approved',
    element: lazy(() =>
      import('pages/admin/estate-request/ApproveEstateRequest')
    ),
    useAuth: true,
    permission: 'view_request'
  },
  {
    path: '/admin/requests/category',
    element: lazy(() => import('pages/admin/estate-request/RequestCategory')),
    useAuth: true,
    permission: 'view_requestcategory'
  },
  {
    path: '/admin/user/residents',
    element: lazy(() => import('pages/admin/user-management')),
    useAuth: true,
    permission: 'residents_management'
  },
  {
    path: '/admin/user/resident-ktc',
    element: lazy(() => import('pages/admin/user-management/ResidentKCI')),
    useAuth: true,
    permission: 'kct_management'
  },
  {
    path: '/admin/user/roles',
    element: lazy(() => import('pages/admin/user-management/Roles')),
    useAuth: true,
    permission: 'role_management'
  },
  {
    path: '/admin/user/users',
    element: lazy(() => import('pages/admin/user-management/Users')),
    useAuth: true,
    permission: 'view_user'
  },
  {
    path: '/admin/polls',
    element: lazy(() => import('pages/admin/polls')),
    useAuth: true,
    permission: 'view_poll'
  },
  {
    path: '/admin/polls/:id',
    element: lazy(() => import('pages/admin/polls/Poll')),
    useAuth: true,
    permission: 'view_poll'
  },
  {
    path: '/admin/complaints',
    element: lazy(() => import('pages/admin/complaints')),
    useAuth: true,
    permission: 'complaint_management'
  },
  {
    path: '/admin/complaints/view/:id',
    element: lazy(() => import('pages/admin/complaints/ViewComplaint')),
    useAuth: true,
    permission: 'complaint_management'
  },
  {
    path: 'complaints-category',
    element: lazy(() => import('pages/admin/complaints/Category')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/admin/advert/adverts',
    element: lazy(() => import('pages/admin/advert')),
    useAuth: true,
    permission: 'change_advert'
  },
  {
    path: '/admin/advert',
    element: lazy(() => import('pages/admin/advert/Adverts')),
    useAuth: true,
    permission: 'view_advert'
  },
  {
    path: '/admin/report/transactions',
    element: lazy(() => import('pages/admin/Report')),
    useAuth: true,
    permission: 'report_management'
  },
  {
    path: '/admin/report/settlements',
    element: lazy(() =>
      import('pages/admin/settlement/SettlementRequestAccounts')
    ),
    useAuth: true,
    permission: 'report_management'
  },
  {
    path: '/admin/report/settlements/:id/transactions',
    element: lazy(() =>
      import('pages/admin/settlement/SettlementRequestAccountTransactions')
    ),
    useAuth: true,
    permission: 'report_management'
  },
  {
    path: '/admin/activity-log',
    element: lazy(() => import('pages/admin/ActivityLog')),
    useAuth: true,
    permission: 'view_activity_log'
  },
  {
    path: '/admin/user-profile',
    element: lazy(() => import('pages/admin/UserProfile')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/admin/wallet',
    element: lazy(() => import('pages/admin/Wallet')),
    useAuth: true,
    permission: 'wallet_management'
  },
  {
    path: '/admin/invoice',
    element: lazy(() => import('pages/admin/Invoice')),
    useAuth: true,
    permission: ''
  },
  {
    path: '/admin/user/manual-token',
    element: lazy(() => import('pages/admin/manual-token')),
    useAuth: true,
    permission: 'manual_token_generation'
  },
  {
    path: '/admin/user/clear-tamper',
    element: lazy(() => import('pages/admin/clear-tamper-token')),
    useAuth: true,
    permission: 'clear_tamper_generation'
  }
];

const publicRoutes = [];

export const adminRoutes = {
  privateRoutes,
  publicRoutes
};
