/* eslint-disable  */

import Api from './axios';

export const queryParamsHelper = (queryParams) => {
  let queries = '?';
  delete queryParams?.filterValue;
  for (let [key, value] of Object.entries(queryParams || {})) {
    if (!!key && key !== undefined && key !== null && value !== '') {
      if (typeof value === 'object') {
        value.forEach((i) => {
          queries += `${key}=${i}&`;
        });
      } else {
        queries += `${key}=${value}&`;
      }
    }
  }
  return queries?.substring(0, queries?.length - 1);
};

export const fetchEstatePropertyType = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/property/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/property/`).then((res) => res.data);
};

export const fetchEstatePropertyTypeById = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`estate/property/${id}`).then((res) => res.data);
};

export const fetchEstatePartnerTypeById = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`estate/partner/${id}`).then((res) => res.data);
};

export const fetchEstatePartnerType = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/partner/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/partner/`).then((res) => res.data);
};

export const fetchBills = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`bill/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`bill/`).then((res) => res.data);
};

export const fetchAvailableBills = async () => {
  return Api.get(`bill/available/bills/`).then((res) => res.data);
};

export const fetchBillById = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`bill/${id}/`).then((res) => res.data);
};

export const fetchEstateProperties = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/property/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/property/`).then((res) => res.data);
};

export const fetchEstateProperty = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`estate/property/${id}/`).then((res) => res.data);
};

export const fetchEstatePartners = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/partner/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/partner/`).then((res) => res.data);
};

export const fetchEstatePartner = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`estate/partner/${id}/`).then((res) => res.data);
};

export const fetchDashBoradOverview = async ({ queryKey }) => {
  return Api.get(`dashboard/admin/overview/`).then((res) => res.data);
};
export const fetchDashBoradOverviewSuper = async ({ queryKey }) => {
  return Api.get(`dashboard/superadmin/overview/`).then((res) => res.data);
};

export const fetchDashBoradChartSuper = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`dashboard/superadmin/chart/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`dashboard/superadmin/chart/`).then((res) => res.data);
};

export const fetchEstate = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  return Api.get(`estate/data/${queryParams}`).then((res) => res.data);
};

export const fetchVendingServices = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/vending/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/vending/`).then((res) => res.data);
};

export const Fetchtokens = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  return Api.get(`order/token/manual/list/${queryParams}`).then(
    (res) => res.data
  );
};

export const fetchDashBoradOverviewResidents = async ({ queryKey }) => {
  return Api.get(`dashboard/resident/overview/`).then((res) => res.data);
};

export const fetchUsers = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`auth/users/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`auth/users/`).then((res) => res.data);
};

export const fetchUsersBills = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`bill/user/bills/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`bill/user/bills/`).then((res) => res.data);
};

export const fetchUserById = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`auth/users/${id}/`).then((res) => res.data);
};

export const fetchPayments = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`payments/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`payments/`).then((res) => res.data);
};

export const fetchOrders = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`order/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`order/`).then((res) => res.data);
};

export const fetchOrder = async ({ queryKey }) => {
  const [, { reference }] = queryKey;

  return Api.get(`order/retrieve/${reference}/`).then((res) => res.data);
};

export const fetchOustanding = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`outstanding/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`outstanding/`).then((res) => res.data);
};

export const oustandingCsv = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`outstanding/export/csv/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`outstanding/export/csv/`).then((res) => res.data);
};

export const fetchOustandingById = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`outstanding/${id}/`).then((res) => res.data);
};

export const verifyWalletFunding = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`wallet/${id}/verify/`).then((res) => res.data);
};

export const fetchWallet = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`wallet/transactions/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`wallet/transactions/`).then((res) => res.data);
};

export const fetchEstateInformation = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  const [, { id }] = queryKey;
  if (queryParams) {
    return Api.get(`estate/information/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/information/${id}`).then((res) => res.data);
};

export const fetchEstateInformationById = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`estate/information/${id}`).then((res) => res.data);
};

export const fetchEstateRequest = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  const [, { id }] = queryKey;
  if (queryParams) {
    return Api.get(`estate/request/data/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`estate/request/data/`).then((res) => res.data);
};

export const fetchEstateMyRequest = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  const [, { id }] = queryKey;
  if (queryParams) {
    return Api.get(`estate/request/data/my-requests/all/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`estate/request/data/my-requests/all/`).then(
    (res) => res.data
  );
};

export const fetchEstateRequestyId = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`estate/request/data/${id}`).then((res) => res.data);
};

export const fetchEstateRApprovedequest = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);

  if (queryParams) {
    return Api.get(`estate/request/approval/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`estate/request/approval/`).then((res) => res.data);
};

export const fetchEstateRequestCategories = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);

  if (queryParams) {
    return Api.get(`estate/request/category/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`estate/request/category/`).then((res) => res.data);
};

export const fetchEstateRequestApprovals = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);

  if (queryParams) {
    return Api.get(`estate/request/approvers/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`estate/request/approvers/`).then((res) => res.data);
};

export const fetchEstateRequestCategory = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`estate/request/category/${id}`).then((res) => res.data);
};

export const fetchEstatePolls = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`poll/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`poll/`).then((res) => res.data);
};

export const fetchPollResultCSV = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`poll/${id}/export/result`).then((res) => res.data);
};

export const fetchEstateRequestPoll = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`poll/${id}`).then((res) => res.data);
};

export const fetchEstateComplaints = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`ticket/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`ticket/`).then((res) => res.data);
};

export const fetchEstateComplaintsCategory = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`ticket/category/data/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`ticket/category/data/`).then((res) => res.data);
};

export const fetchTicketCategoryById = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`ticket/category/data/${id}`).then((res) => res.data);
};

export const fetchEstateComplaint = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`ticket/${id}`).then((res) => res.data);
};

export const fetchBookings = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`booking/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`booking/`).then((res) => res.data);
};

export const fetchBooking = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`booking/${id}`).then((res) => res.data);
};

export const fetchGroups = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`auth/groups/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`auth/groups/`).then((res) => res.data);
};

export const fetchGroup = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`auth/groups/${id}`).then((res) => res.data);
};

export const fetchPermissions = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`auth/groups/permissions/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`auth/groups/permissions/`).then((res) => res.data);
};

export const fetchAdverts = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`advert/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`advert/`).then((res) => res.data);
};

export const fetchAdvert = async ({ queryKey }) => {
  const [, { id }] = queryKey;

  return Api.get(`advert/${id}`).then((res) => res.data);
};

export const fetchCurrentAds = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`advert/current/ads/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`advert/current/ads/`).then((res) => res.data);
};

export const dashboardChartData = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`dashboard/admin/chart/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`dashboard/admin/chart/`).then((res) => res.data);
};

export const dashboardResidentChartData = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`dashboard/resident/chart/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`dashboard/resident/chart/`).then((res) => res.data);
};

export const walletChart = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`dashboard/wallet/chart/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`dashboard/wallet/chart/`).then((res) => res.data);
};

export const getActivityLog = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/activity/logs/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`estate/activity/logs/`).then((res) => res.data);
};

export const fetchKct = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`auth/users/kct-log/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`auth/users/kct-log/`).then((res) => res.data);
};

export const paymentMethods = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`wallet/payment-methods/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`wallet/payment-methods/`).then((res) => res.data);
};

export const fetchFaq = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/faq/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/faq/`).then((res) => res.data);
};

export const fetchConfig = async ({ queryKey }) => {
  return Api.get(`config/`).then((res) => res.data);
};

export const categoryData = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`ticket/category/data/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`ticket/category/data/`).then((res) => res.data);
};

export const fetchCart = async ({ queryKey }) => {
  return Api.get(`cart/`).then((res) => res.data);
};

export const bookingCsv = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`booking/export/csv/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`booking/export/csv/`).then((res) => res.data);
};

export const paymentCsv = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`payments/export/csv/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`payments/export/csv/`).then((res) => res.data);
};

export const userCsv = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`auth/users/export/csv/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`auth/users/export/csv/`).then((res) => res.data);
};

export const fetchEsates = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/data/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/data/`).then((res) => res.data);
};

export const fetchEstateTypeById = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`estate/data/${id}`).then((res) => res.data);
};

export const fetchEsatesDirectory = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/directory/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/directory/`).then((res) => res.data);
};

export const fetchEstateVendingById = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`estate/vending/${id}`).then((res) => res.data);
};

export const fetchEstateVending = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/vending/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/vending/`).then((res) => res.data);
};
export const fetchEstateGatewayById = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  return Api.get(`estate/payment/${id}`).then((res) => res.data);
};

export const fetchEstateGateway = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/payment/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/payment/`).then((res) => res.data);
};

export const fetchVendingProviders = async () => {
  return Api.get(`estate/vending/all/providers/`).then((res) => res.data);
};
export const fetchGateways = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/payment/all/gateways/${queryParams}`).then(
      (res) => res.data
    );
  }
};
export const fetchNotifications = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`estate/information/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`estate/information/`).then((res) => res.data);
};

export const fetchLinkedAccounts = async () => {
  return Api.get(`auth/users/linked-accounts/`).then((res) => res.data);
};

export const fetchEstateSettlementAccounts = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`settlement/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`settlement/`).then((res) => res.data);
};
export const fetchESettlementAccountTransactions = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`settlement/transactions/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`settlement/transactions/`).then((res) => res.data);
};

export const fetchESettlementAccountPayments = async ({ queryKey }) => {
  const id = queryKey[1].id;
  // delete queryKey[1].id
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`settlement/${id}/payments/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`settlement/${id}/payments`).then((res) => res.data);
};

export const fetchESettlementRequests = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`settlement/request/${queryParams}`).then((res) => res.data);
  }
  return Api.get(`settlement/request/`).then((res) => res.data);
};
export const fetchESettlementRequestAccounts = async ({ queryKey }) => {
  const id = queryKey[1].id;
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`settlement/request/${id}/accounts/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`settlement/request/${id}/accounts`).then((res) => res.data);
};
export const fetchESettlementRequestAccountTransactions = async ({
  queryKey
}) => {
  const id = queryKey[1].id;
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`settlement/request/${id}/transactions/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`settlement/request/${id}/transactions/`).then(
    (res) => res.data
  );
};

export const fetchBanks = async () => {
  return Api.get(`settlement/banks/`).then((res) => res.data);
};
export const fetchEstateSettledSettlements = async ({ queryKey }) => {
  const id = queryKey[1].id;
  const queryParams = queryParamsHelper(queryKey?.[1]);
  if (queryParams) {
    return Api.get(`settlement/request/estate/${queryParams}`).then(
      (res) => res.data
    );
  }
  return Api.get(`settlement/request/estate/`).then((res) => res.data);
};
export const fetchClearTamperTokens = async ({ queryKey }) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);
  return Api.get(`meter/clear-tamper/list/${queryParams}`).then(
    (res) => res.data
  );
};
